<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" class="mb-3">
                    <div class="mt-3">
                        <b-card-group deck class="mb-3">
                            <b-card border-variant="light" class="text-start" header-tag="header">
                                <template #header>
                                    <h6 class="mb-0 d-flex justify-content-between">
                                        <span>{{ $t('full_name') }}: {{ computedPerson.name }} {{
                                            computedPerson.surname }}</span>
                                        <span>{{ $t('national_id') }}: {{ computedPerson.national_id }} </span>
                                    </h6>
                                </template>
                                <b-card-text class="d-flex justify-content-between">
                                    <div class="d-flex flex-column">
                                        <span>{{ $t('academic_year') }}: {{ formData.academic_year }}</span>
                                        <span class="my-2">{{ $t('program_code') }}: {{
                                            formData.student_program?.program_code }}</span>
                                        <span v-if="formData.city">{{ $t('city') }}: {{ formData.city }} </span>
                                        <span class="my-2">{{ $t('room') }} {{ $t('name') }}: {{
                                            getRoomName(formData.unihall_quota_id) }} </span>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <span>{{ $t('student_number') }}: {{ studentNumber
                                        }}</span>
                                        <span class="my-2">{{ $t('birthdate') }}: {{
                                            $moment(computedPerson.birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                        }}</span>
                                        <span>{{ $t('gender') }}: {{ computedPerson.gender }}</span>
                                        <span class="my-2">{{ $t('gsm_no') }}: {{ computedPerson.mobile_tel }}</span>
                                    </div>
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                    </div>
                </b-col>

                <template v-if="formType !== 'room-update'">

                    <b-col cols="12" md="6">
                        <Validation-provider name="payment_status" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('payment_status')">
                                <b-form-select :disabled="disabled" v-model="formData.payment_status"
                                    :class="errors[0] ? 'is-invalid' : ''" :options="[
                                        { text: $t('yes'), value: 'E' },
                                        { text: $t('no'), value: 'H' },
                                    ]" :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </Validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('unihall_quota')">
                            <unihall-quota-selectbox disabled v-model="formData.unihall_quota_id" />
                        </b-form-group>
                    </b-col>
                </template>
            </b-row>

        </ValidationObserver>
        <ValidationObserver ref="formPriceModalValidate">
            <b-row>
                <template v-if="formType === 'room-update'">
                    <b-col cols="12" md="6">
                        <Validation-provider name="roomUpdate.unihall_quota_id" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('unihall_quota')">
                                <unihall-quota-selectbox v-model="roomUpdate.unihall_quota_id"
                                    :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </Validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('unihall_quotas_price_title')">
                            <Validation-provider name="roomUpdate.unihall_quota_id" rules="required" v-slot="{ errors }">
                                <unihall-quota-price-selectbox :quota="roomUpdate.unihall_quota_id"
                                    v-model="roomUpdate.unihall_quota_price_id" :validateError="errors[0]" />
                            </Validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('payment')">
                            <Validation-provider name="roomUpdate.payment_type" rules="required" v-slot="{ errors }">
                                <b-form-select v-model="roomUpdate.payment_type"
                                    :options="[{ text: $t('credit_card'), value: 'credit_card' }, { text: $t('money_order'), value: 'money_order' }]"
                                    :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </Validation-provider>
                        </b-form-group>
                    </b-col>
                </template>
            </b-row>
        </ValidationObserver>

        <div class="d-flex justify-content-center mt-2">
            <b-button class="px-5" :variant="formType == 'delete' ? 'danger' : 'primary'" @click="createForm"
                :disabled="loading">
                {{ buttonText }}
            </b-button>
        </div>

    </div>
</template>
<script>
// Services
import UnihallApplicationsService from "@/services/UnihallApplicationsService";

export default {
    name: "UnihallQuotaPrice",
    props: {
        formType: {
            type: String,
            required: true,
        },
        formId: {
            type: Number,
            default: -1,
        },
        value: {
            type: Object,
            default: () => { },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        unihallQuotas: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            roomUpdate: {}
        };
    },
    watch: {
        formType: {
            handler(val) {
                this.$refs?.formModalValidate?.reset();

                if (val === "room-update") {
                    
                    const item = {
                        unihall_quota_id: this.formData.unihall_quota_id,
                        unihall_quota_price_id: this.formData.unihall_quota_price_id,
                        payment_type: this.formData.payment_type
                    }
                    this.roomUpdate = { ...item }

                }
            },
            immediate: true
        },
    },
    computed: {
        formData: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
                return val;
            },
        },
        studentNumber() {
            if (this.formData?.student?.student_number) {
                return this.formData.student.student_number
            }
            return ""
        },
        computedPerson() {
            if (this.formData?.student?.person) {
                return this.formData.student.person
            }
            return {}
        },
        buttonText() {
            if (this.formType === "show") {
                return this.$t("close");
            } else if (this.formType === "add") {
                return this.$t("save");
            } else if (this.formType === "update") {
                return this.$t("update");
            } else if (this.formType === "delete") {
                return this.$t("delete");
            }
            else if (this.formType === "room-update") {
                return this.$t("update");
            }
            return "";
        },
    },
    methods: {
        async createForm() {
            if (this.formType === "show") {
                this.$emit("close");
                return;
            }
            const valid = await this.$refs[`form${this.formType === 'room-update' ? 'Price' : ''}ModalValidate`].validate();
            if (!valid && (this.formType !== "delete")) {
                return;
            }
            this.loading = true;

            let response = null;
            let result = null;

            this.formData.unihall_quota_id = this.formId;
            this.formData.price = Number(
                Number(this.formData.price).toFixed(2)
            );

            switch (this.formType) {
                case "update":
                    response = await UnihallApplicationsService.update(
                        { payment_status: this.formData.payment_status, unihall_application_id: this.formId }
                    )
                        .catch((e) => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                    break;

                case "delete":
                    result = await this.$swal.fire({
                        text: this.$t("are_you_sure_to_delete"),
                        showCancelButton: true,
                        confirmButtonText: this.$t("yes"),
                        cancelButtonText: this.$t("no"),
                    });
                    if (result.isConfirmed) {
                        response = await UnihallApplicationsService.remove(
                            this.formId
                        )
                            .catch((e) => {
                                this.showErrors(
                                    e,
                                    this.$refs.formModalValidate
                                );
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        return
                    }
                    break;

                case "room-update":
                    response = await UnihallApplicationsService.updateRoomAndPrice(
                        {
                            unihall_application_id: this.formId,
                            ...this.roomUpdate
                        }
                    )
                        .catch((e) => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                    break;

                default:
                    break;
            }

            if (!response) {
                this.$toast.error(this.$t("something_went_wrong"));
                return;
            }

            if (response?.data?.success) {
                this.formClear();
                this.$refs.formModalValidate.reset();
                if (this.formType === "update" || this.formType === "delete"|| this.formType === "room-update") {
                    this.$emit("close");
                }
            }
            this.$toast.success(this.$t("api." + response.data.message));
        },
        formClear() {
            this.formData = {};
            this.roomUpdate = {};
        },
        getRoomName(id) {
            const find = this.unihallQuotas.find(item => item.id == id)
            if (find) {
                return find.name
            }
            return ""
        },
    },
};
</script>
<style lang=""></style>
